import { CDN_URL } from "@/common/config"

export default {
  methods: {
    getMembersAvatarsData(members) {
      let avatarsData = []
      members.forEach((member) => {
        let item = {
          _key: member._key,
          name: member?.engine?.name,
          engine: member.engine,
          icon: member?.location?.icon
            ? `${CDN_URL}${member?.location?.icon}`
            : `${window.location.origin}/images/40x40-808080.png`,
          status: member.status,
          flag: member?.location?.flag ? member?.location?.flag : "",
          location: member?.location,
          output_state: member.output_state,
          output_permission: member.output_permission,
        }
        avatarsData.push(item)
      })
      return avatarsData
    },
  },
}
