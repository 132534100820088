<template>
  <div>
    <v-data-table
      v-bind="$attrs"
      v-on="$listeners"
      :footer-props="footerOptions"
      :search="finalSearch"
      :options.sync="options"
      :page.sync="options.page"
      :items-per-page.sync="options.itemsPerPage"
      class="moon-table"
    >
      <template v-slot:top="{ options, updateOptions }">
        <v-row class="pa-3">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              outlined
              v-model="defaultSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <SortMobile
          :headers="headers"
          :options="options"
          @update:options="updateOptions"
          v-if="!$vuetify.breakpoint.smAndUp"
        />
      </template>

      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>

      <!--    <template  v-for="(index, name) in $slots" v-slot:[name] >-->
      <!--      <slot v-if="name === 'no-data'" :name="name"></slot>-->
      <!--    </template>-->
    </v-data-table>
  </div>
</template>

<script>
import { get, forEach, has, isNull, omit } from "lodash"
import SortMobile from "@/components/table/SortMobile"

export default {
  name: "DataTableMoon",

  inheritAttrs: false,

  props: {
    customSearch: {
      type: String,
      default: "",
    },
    delimiter: {
      type: String,
      default: "table",
    },
  },

  data() {
    return {
      skipParams: {
        keep: null,
        p: 1,
        pp: 10,
      },
      defaultSearch: "",
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      footerOptions: {
        "items-per-page-text": "",
        "items-per-page-options": [5, 10, 15, 20, 30],
      },
      itemsCount: 0,
    }
  },

  created() {
    this.initRoute()
  },

  watch: {
    options: {
      handler() {
        this.refreshTableData()
      },
      deep: true,
    },
    finalSearch: function () {
      this.refreshTableData()
    },
    // currentPageItemsCount: function (val) {
    //   if (this.options.page && (val < this.options.page * this.options.itemsPerPage)) {
    //     this.options.page = 1
    //     this.refreshTableData()
    //   }
    // },
  },

  methods: {
    /**
     * Get table components options, for send to API for get table data
     *
     * @returns {{params: {p, pp: number, keep: *}}}
     */
    getTableComponentsParams() {
      let self = this
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      let getParams = {
        p: page || 1,
        pp: itemsPerPage,
        keep: self.keep,
      }
      if (self.finalSearch !== "") {
        getParams.search = self.finalSearch
      }
      if (sortBy && sortDesc && sortBy.length > 0 && sortDesc.length > 0) {
        getParams.sortby = get(sortBy, "[0]")
        getParams.order = get(sortDesc, "[0]") ? "DESC" : "ASC"
      }
      return getParams
    },
    /**
     * Get params. for send to request API endpoint, component options merged with URL query params
     *
     * @returns {{params: {p, pp: number, keep: *}}}
     */
    getTableServerParams() {
      return { ...this.getTableComponentsParams(), ...this.$route.query }
    },
    /**
     * Update route with query params
     * @returns {Promise<unknown>}
     */
    updateRoute() {
      let self = this
      return new Promise((resolve) => {
        let query = self.getQueryParams()
        let urlQuery = JSON.parse(JSON.stringify(self.$route.query))
        for (let key in query) {
          urlQuery[`${this.delimiter}.${key}`] = query[key]
        }
        for (let key in urlQuery) {
          if (key.includes(`${this.delimiter}.`)) {
            let param = key.replace(`${this.delimiter}.`, "")
            if (!Object.prototype.hasOwnProperty.call(query, param)) {
              delete urlQuery[key]
            }
          }
        }
        self.$router
          .replace({ name: self.$route.name, query: urlQuery })
          .then(() => {
            resolve()
          })
          .catch(() => {
            // catch error
            resolve()
          })
      })
    },
    /**
     * Get query params for insert in route and skip those that are setted in skipParams by logic:
     * - if param value is null skip
     * - if param value is setted check by value
     *
     * @returns {{[p: string]: *}}
     */
    getQueryParams() {
      let self = this
      let query = {}
      let rawQueryParams = self.getTableComponentsParams()
      forEach(rawQueryParams, (val, key) => {
        if (
          !val ||
          (has(self.skipParams, key) &&
            (isNull(self.skipParams[key]) || val === self.skipParams[key]))
        ) {
          rawQueryParams = omit(rawQueryParams, key)
        }
      })
      query = { ...query, ...rawQueryParams }
      return query
    },
    /**
     * Refresh route and table with pagination, sort, filter data
     */
    refreshTableData() {
      let self = this
      self.updateRoute().then(() => {
        self.$emit("init-table-data")
      })
    },

    /**
     * Get params from URL and assign to params of table options
     */
    initRoute() {
      let self = this
      let query = self.$route.query

      if (Object.prototype.hasOwnProperty.call(query, `${this.delimiter}.p`)) {
        self.options.page = parseInt(query[`${this.delimiter}.p`])
      }
      if (Object.prototype.hasOwnProperty.call(query, `${this.delimiter}.pp`)) {
        self.options.itemsPerPage = parseInt(query[`${this.delimiter}.pp`])
      }
      if (
        Object.prototype.hasOwnProperty.call(query, `${this.delimiter}.sortby`)
      ) {
        self.options.sortBy = [query[`${this.delimiter}.sortby`]]
      }
      if (
        Object.prototype.hasOwnProperty.call(query, `${this.delimiter}.order`)
      ) {
        self.options.sortDesc = [
          query[`${this.delimiter}.order`] === "DESC" ? true : false,
        ]
      }
      if (
        Object.prototype.hasOwnProperty.call(query, `${this.delimiter}.search`)
      ) {
        self.$emit("update-search", query[`${this.delimiter}.search`])
      }
    },
  },

  computed: {
    finalSearch() {
      if (this.defaultSearch !== "") return this.defaultSearch
      if (this.customSearch !== "") return this.customSearch
      return this.defaultSearch
    },
  },
}
</script>

<style lang="scss" scoped>
.moon-table::v-deep {
  &.v-data-table {
    border: none !important;

    thead {
      tr th {
        border-bottom: 2px solid #0087a3 !important;
        background-color: #323435;
        vertical-align: middle;
        font-size: 18px;
        font-weight: inherit;
      }
    }

    tr {
      height: 80px;
      background-color: #2f3132;

      &:hover {
        background-color: #494949 !important;
      }
    }

    td {
      line-height: 20px;
      margin-bottom: 30px;
      border-bottom: none !important;
      padding-top: 15px;
      padding-bottom: 15px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }

      .two-lines {
        max-width: 300px;

        & .subhead {
          color: #616262;
        }
      }
    }

    .theme--dark.v-icon {
      color: #747678;
    }
  }
}
</style>
