<template>
  <div style="height: 100%">
    <v-card class="events-wrapper d-flex flex-column" style="height: 100%">
      <div
        v-if="!filterByLocation"
        class="d-flex justify-space-between align-baseline pa-6"
      >
        <div class="title-three d-flex">
          <div>
            <div class="d-flex flex-column justify-space-between">
              <div class="title">Events</div>
            </div>
          </div>
        </div>
        <div class="events-wrapper__filter-left"></div>
        <div
          class="events-wrapper__filter-right filter-day-event d-flex justify-space-between align-center"
        >
          <div class="events-wrapper__filter-location">
            <SelectLocationsAsync
              v-model="filterByLocation"
              outlined
              label="Filter by location"
              :multiple="false"
              item-text="name"
              :error-messages="errors.collect(`form-event-members.location`)"
              field="location"
              :menu-props="{ maxHeight: 304 }"
              return-object
              hide-details
              class="events-wrapper__timezone-select"
              clearable
              dense
              :customFilters="customFilterLocations"
              data-testid="filter-location"
            />
          </div>

          <v-btn
            v-if="!link_hash"
            color="#C5C6C8"
            outlined
            class="events-wrapper__add px-0"
            height="40"
            to="/store/event/create"
            data-testid="btn-event-create"
          >
            <v-icon color="#636364" size="20">mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="filterByLocation" class="d-flex justify-space-between pa-6">
        <div class="title-three d-flex align-center">
          <v-btn
            color="#C5C6C8"
            outlined
            class="filter-day-event__back px-0 mr-1"
            height="41"
            @click="filterByLocation = null"
            text
            data-testid="btn-back-events-list"
          >
            <v-icon color="#636364" size="20">mdi-keyboard-backspace</v-icon>
          </v-btn>

          <div
            class="img"
            :style="{
              backgroundImage: `url(${
                filterByLocation.icon ? filterByLocation.icon : emptyIconUrl
              })`,
              backgroundSize: 'cover',
              width: '41px',
              height: '41px',
            }"
          ></div>
          <div>
            <div class="d-flex flex-column justify-space-between">
              <div class="title">{{ filterByLocation.name }}</div>
              <div class="subtitle">{{ filterByLocation.engine.name }}</div>
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div
          class="events-wrapper__filter-left d-flex justify-space-between align-center"
        >
          <v-btn
            icon
            color="#757779"
            class="filter-day-event__prev"
            small
            @click="setFilterDate('prev')"
            data-testid="btn-filter-prev-date"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-btn
            icon
            color="#757779"
            class="filter-day-event__next"
            small
            @click="setFilterDate('next')"
            data-testid="btn-filter-next-date"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div
          class="events-wrapper__filter-right filter-day-event align-center d-flex justify-space-between"
        >
          <v-btn
            class="filter-day-event__today"
            outlined
            @click="setFilterDate()"
            color="#C5C6C8"
            height="32"
            data-testid="btn-filter-today-date"
          >
            Today
          </v-btn>

          <div class="filter-day-event__date">
            <DateFilter
              :date.sync="filterByDate"
              label=""
              :prepend-icon="null"
              :hide-details="true"
              :clearable="false"
              width="130px"
              height="30px"
              inputClasses="events-wrapper__date-filter"
              data-testid="filter-today-date"
            />
          </div>

          <v-btn
            color="#C5C6C8"
            outlined
            class="filter-day-event__more px-0"
            height="32"
          >
            <v-icon color="#636364" size="20">mdi-dots-vertical</v-icon>
          </v-btn>
        </div>
      </div>

      <v-card-text
        class="px-3 full-height flex-grow-1"
        style="padding-bottom: 0"
      >
        <v-row
          class="d-flex flex-column flex-grow-1 justify-start"
          style="min-height: 100%"
        >
          <v-expansion-panels
            accordion
            v-model="panel"
            class="events-wrapper__panel d-flex flex-column"
            style="min-height: 100%"
            @click.native.stop="doSomething"
          >
            <v-expansion-panel
              :key="'sheduled-events'"
              @click.native.stop="doSomething"
              class="d-flex flex-column"
              :readonly="panel == 0"
              data-testid="panel-sheduled-events"
            >
              <v-expansion-panel-header
                hide-actions
                @click.native.stop="doSomething"
                class="justify-self-start flex-grow-0"
              >
                <template v-slot:default="{ open }">
                  <div class="px-6 d-flex justify-space-between align-center">
                    <div
                      class="events-wrapper__type-title d-flex justify-space-between align-center"
                    >
                      <span class="mr-4">Sheduled Events Today</span>
                      <v-icon v-if="open">mdi-chevron-down</v-icon>
                      <v-icon v-if="!open">mdi-chevron-up</v-icon>
                    </div>

                    <div
                      class="events-wrapper__timezone d-flex align-center"
                      @click.stop="&quot;javascript:void(0)&quot;"
                    >
                      <div
                        class="events-wrapper__timezone-container d-flex justify-center"
                      >
                        <v-autocomplete
                          v-model="filterByTimezone"
                          :items="timezones"
                          label="Time zone"
                          outlined
                          dense
                          hide-details
                          class="events-wrapper__timezone-select"
                          data-testid="filter-sheduled-timezone"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="">
                <DataTableMoon
                  :headers="headersSheduled"
                  :items="sheduledEvents"
                  item-key="_key"
                  class="elevation-0 events-list"
                  :calculate-widths="true"
                  :loading="showTableLoader"
                  :loading-text="
                    showTableLoader ? 'Loading…' : 'No data available'
                  "
                  sortable
                  :multi-sort="false"
                  :server-items-length="sheduledEventsCount"
                  v-on:init-table-data="getEventsSheduledList"
                  ref="refTableEventsSheduled"
                  hide-default-header
                  delimiter="sheduled"
                  data-testid="table-events-sheduled"
                >
                  <template v-slot:top></template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        <v-row class="align-center flex-nowrap">
                          <v-col
                            :class="{
                              'events-list__time': filterByLocation,
                              'events-list__date-time': !filterByLocation,
                            }"
                            @click="handleClickRow(item)"
                          >
                            <div data-testid="sheduled-full-hour-start">
                              <span
                                v-if="!filterByLocation"
                                class="event-hour event-duration-number events-list__duration-number"
                                data-testid="sheduled-datetime-start"
                              >
                                {{
                                  item.start_date
                                    | unixDateTimeFormat("MMM D, YYYY h:mm a")
                                }}
                              </span>
                              <span
                                v-if="filterByLocation"
                                class="event-hour events-list__hour"
                                data-testid="sheduled-hour-start"
                              >
                                {{ item.start_date | unixTimeFormat() }}
                              </span>
                              <span
                                v-if="filterByLocation"
                                class="event-hour-midnight"
                                data-testid="sheduled-pm-am-start"
                              >
                                {{ item.start_date | unixTimeFormat("a") }}
                              </span>
                            </div>
                          </v-col>
                          <v-col
                            class="events-list__duration"
                            @click="handleClickRow(item)"
                          >
                            <div>
                              <span
                                class="event-duration-number events-list__duration-number"
                                data-testid="sheduled-duration"
                              >
                                {{ item.duration }}
                              </span>
                              <!--                              <span class="event-duration-unit">m</span>-->
                            </div>
                          </v-col>
                          <v-col
                            class="events-list__icon"
                            @click="handleClickRow(item)"
                          >
                            <v-avatar data-testid="sheduled-event-icon">
                              <img :src="item.icon" size="38" alt="icon" />
                            </v-avatar>
                          </v-col>
                          <v-col
                            class="events-list__name"
                            @click="handleClickRow(item)"
                          >
                            <div
                              class="event-name"
                              data-testid="sheduled-event-name"
                            >
                              {{ item.name }}
                            </div>
                          </v-col>
                          <v-col
                            class="events-list__locations"
                            @click="handleClickRow(item)"
                          >
                            <div data-testid="sheduled-event-members-icons">
                              <AvatarGroups
                                :items="getMembersAvatarsData(item.members)"
                              />
                            </div>
                          </v-col>
                          <v-col class="events-list__status">
                            <div class="d-flex justify-space-between">
                              <EventStatusChip
                                :event="item"
                                @click.native="handleClickRow(item)"
                                class="events-list__status-wrap"
                                data-testid="sheduled-event-status"
                              />

                              <div
                                v-if="!filterByLocation && !link_hash"
                                class="events-list__controls-wrap"
                              >
                                <v-menu
                                  v-if="
                                    isSuperUser ||
                                    (currentUser.group_key === item.group_key &&
                                      (($can('event.update', 'ws_event') &&
                                        $can('update', 'events')) ||
                                        ($can('event.delete', 'ws_event') &&
                                          $can('delete', 'events')) ||
                                        $can('create', 'un_connections') ||
                                        $can('update', 'un_connections') ||
                                        $can('delete', 'un_connections')))
                                  "
                                  transition="slide-y-transition"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      fab
                                      dark
                                      x-small
                                      outlined
                                      v-bind="attrs"
                                      v-on="on"
                                      :color="Pallette.actionButtons.menu"
                                      class="clear-padding"
                                      data-testid="btn-sheduled-more-actions"
                                    >
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list dense>
                                    <v-list-item
                                      :to="`/store/event/edit/${item._key}/connections`"
                                      v-if="
                                        isSuperUser ||
                                        $can('create', 'un_connections') ||
                                        $can('update', 'un_connections') ||
                                        $can('delete', 'un_connections')
                                      "
                                      data-testid="btn-sheduled-edit-event-connections"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Edit connections
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="Pallette.actionButtons.edit"
                                        >
                                          mdi-lan-connect
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                    <v-list-item
                                      :to="`/store/event/edit/${item._key}`"
                                      v-if="
                                        isSuperUser ||
                                        ($can('event.update', 'ws_event') &&
                                          $can('update', 'events'))
                                      "
                                      data-testid="btn-sheduled-edit-event"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Edit event
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="Pallette.actionButtons.edit"
                                        >
                                          mdi-pencil
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                    <v-list-item
                                      :to="`/store/event/control/${item._key}`"
                                      v-if="
                                        isSuperUser ||
                                        currentUser.group_key === item.group_key
                                      "
                                      data-testid="btn-sheduled-control-event"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Control event
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="Pallette.actionButtons.edit"
                                        >
                                          mdi-play
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                    <v-list-item
                                      @click="deleteEvent(item)"
                                      v-if="
                                        isSuperUser ||
                                        ($can('event.delete', 'ws_event') &&
                                          $can('delete', 'events'))
                                      "
                                      data-testid="btn-sheduled-delete-event"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Delete event
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="Pallette.actionButtons.delete"
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </DataTableMoon>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
              :key="'any-time-events'"
              class="d-flex flex-column"
              :readonly="panel == 1"
              data-testid="panel-any-time-events"
            >
              <v-expansion-panel-header hide-actions class="flex-grow-0">
                <template v-slot:default="{ open }">
                  <div class="px-6 d-flex justify-space-between align-center">
                    <div
                      class="events-wrapper__type-title d-flex justify-space-between align-center"
                    >
                      <span class="mr-4">Any Time Events</span>
                      <v-icon v-if="open">mdi-chevron-down</v-icon>
                      <v-icon v-if="!open">mdi-chevron-up</v-icon>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <DataTableMoon
                  :headers="headersAny"
                  :items="anyEvents"
                  item-key="_key"
                  class="elevation-0 events-list"
                  :calculate-widths="true"
                  :loading="showTableLoader"
                  :loading-text="
                    showTableLoader ? 'Loading…' : 'No data available'
                  "
                  sortable
                  :multi-sort="false"
                  :server-items-length="anyEventsCount"
                  v-on:init-table-data="getEventsAnyList"
                  ref="refTableEventsAny"
                  hide-default-header
                  delimiter="any"
                  data-testid="table-events-any"
                >
                  <template v-slot:top></template>

                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        <v-row class="align-center flex-nowrap">
                          <v-col
                            class="events-list__icon"
                            @click="handleClickRow(item)"
                          >
                            <v-avatar data-testid="any-event-icon">
                              <img :src="item.icon" size="38" alt="icon" />
                            </v-avatar>
                          </v-col>
                          <v-col
                            class="events-list__name"
                            @click="handleClickRow(item)"
                          >
                            <div
                              class="event-name"
                              data-testid="any-event-name"
                            >
                              {{ item.name }}
                            </div>
                          </v-col>
                          <v-col
                            class="events-list__locations"
                            @click="handleClickRow(item)"
                          >
                            <div data-testid="any-event-members-icons">
                              <AvatarGroups
                                :items="getMembersAvatarsData(item.members)"
                              />
                            </div>
                          </v-col>
                          <v-col class="events-list__status">
                            <div class="d-flex justify-space-between">
                              <EventStatusChip
                                :event="item"
                                class="events-list__status-wrap"
                                @click.native="handleClickRow(item)"
                                data-testid="any-event-status"
                              />

                              <div
                                v-if="!filterByLocation"
                                class="events-list__controls-wrap"
                              >
                                <v-menu
                                  v-if="
                                    isSuperUser ||
                                    (currentUser.group_key === item.group_key &&
                                      (($can('event.update', 'ws_event') &&
                                        $can('update', 'events')) ||
                                        ($can('event.delete', 'ws_event') &&
                                          $can('delete', 'events')) ||
                                        $can('create', 'un_connections') ||
                                        $can('update', 'un_connections') ||
                                        $can('delete', 'un_connections')))
                                  "
                                  transition="slide-y-transition"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      fab
                                      dark
                                      x-small
                                      outlined
                                      v-bind="attrs"
                                      v-on="on"
                                      :color="Pallette.actionButtons.menu"
                                      class="clear-padding"
                                      data-testid="btn-any-more-actions"
                                    >
                                      <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                  </template>
                                  <v-list dense>
                                    <v-list-item
                                      :to="`/store/event/edit/${item._key}/connections`"
                                      v-if="
                                        isSuperUser ||
                                        $can('create', 'un_connections') ||
                                        $can('update', 'un_connections') ||
                                        $can('delete', 'un_connections')
                                      "
                                      data-testid="btn-any-edit-event-connections"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Edit connections
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="Pallette.actionButtons.edit"
                                        >
                                          mdi-lan-connect
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                    <v-list-item
                                      :to="`/store/event/edit/${item._key}`"
                                      v-if="
                                        isSuperUser ||
                                        ($can('event.update', 'ws_event') &&
                                          $can('update', 'events'))
                                      "
                                      data-testid="btn-any-edit-event"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Edit event
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="Pallette.actionButtons.edit"
                                        >
                                          mdi-pencil
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                    <v-list-item
                                      @click="deleteEvent(item)"
                                      v-if="
                                        isSuperUser ||
                                        ($can('event.delete', 'ws_event') &&
                                          $can('delete', 'events'))
                                      "
                                      data-testid="btn-any-delete-event"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Delete event
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-icon>
                                        <v-icon
                                          :color="Pallette.actionButtons.delete"
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </v-list-item-icon>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </DataTableMoon>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
    </v-card>
    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
import { cloneDeep, get } from "lodash"
import { mapGetters } from "vuex"
import moment from "moment"
import Confirm from "@/components/Confirm"
import WsConnMixin from "@/mixins/websocket-connection.js"
import EventCommonMixin from "@/mixins/event/common.js"
import EventMembersMixin from "@/mixins/event/members.js"
import DateFilter from "@/components/filters/DateFilter"
import momentTimezone from "moment-timezone"
import DataTableMoon from "@/components/table/DataTableMoon"
import AvatarGroups from "@/components/avatar/AvatarGroups"
import EventStatusChip from "./EventStatusChip"
import SelectLocationsAsync from "@/components/selects/SelectLocationsAsync"
import { EngineService } from "@/common/api/api.service"

export default {
  props: {
    link_hash: {
      type: [Number, String],
      default: null,
    },
  },

  components: {
    Confirm,
    DateFilter,
    DataTableMoon,
    AvatarGroups,
    EventStatusChip,
    SelectLocationsAsync,
  },

  mixins: [WsConnMixin, EventCommonMixin, EventMembersMixin],

  data() {
    return {
      headersSheduled: [
        {
          text: "Start time",
          align: "center",
          value: "start_time",
          sortable: false,
          width: 50,
        },
        {
          text: "Duration",
          align: "left",
          value: "duration",
          sortable: false,
        },
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: false,
        },
        {
          text: "Participants",
          align: "left",
          value: "participants",
          sortable: false,
        },
        {
          text: "Status",
          align: "left",
          value: "status",
          sortable: false,
        },
      ],
      headersAny: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: false,
        },
        {
          text: "Participants",
          align: "left",
          value: "participants",
          sortable: false,
        },
        {
          text: "Status",
          align: "left",
          value: "status",
          sortable: false,
        },
      ],
      sheduledEvents: [],
      sheduledEventsCount: 0,
      anyEvents: [],
      anyEventsCount: 0,
      panel: 0,
      filterByDate: null,
      filterByLocation: null,
      timezones: momentTimezone.tz.names(),
      localTimezone: momentTimezone.tz.guess(true),
      queryParamsInited: false,
    }
  },

  watch: {
    filterByEngine: function () {
      this.getEventsSheduledList()
      this.getEventsAnyList()
    },
    filterByDate: function () {
      this.getEventsSheduledList()
      this.getEventsAnyList()
    },
    filterByLocation: function (val) {
      if (val) {
        this.setFilterDate()
        if (
          !Object.prototype.hasOwnProperty.call(this.$route.query, "location")
        ) {
          this.$router.replace({
            name: this.$route.name,
            query: { location: val?._key },
          })
        }
      } else {
        if (
          Object.prototype.hasOwnProperty.call(this.$route.query, "location")
        ) {
          let query = cloneDeep(this.$route.query)
          if (Object.prototype.hasOwnProperty.call(query, "location")) {
            delete query.location
          }
          this.$router.replace({ name: this.$route.name, query: query })
        }
        this.filterByDate = null
      }
    },
    filterByTimezone: function () {
      this.getEventsSheduledList()
      this.getEventsAnyList()
    },
  },

  created: function () {
    let self = this
    self.initWebsocketConnection()
    self.$on("event-list", () => {
      self.getEventsSheduledList()
      self.getEventsAnyList()
    })

    self.getFilterLocationKey().then((filterLocationKey) => {
      if (filterLocationKey) {
        self.initLocationFilters(filterLocationKey)
      } else {
        self.queryParamsInited = true
        self.getEventsSheduledList()
        self.getEventsAnyList()
      }
    })
  },

  mounted() {
    this.getPanelMaxHeight()
    window.addEventListener("resize", this.getPanelMaxHeight)
  },

  methods: {
    getFilterLocationKey() {
      let self = this
      return new Promise((resolve) => {
        let query = self.$route.query
        // In case when we have url query param "location"
        if (query?.location) {
          resolve(query.location)
          return
        }
        // When we access link by hash we must set filter by location: priority to role Display
        if (self.link_hash) {
          // @todo make this request in sync with request of SelectLocationsAsync component
          EngineService.locations
            .getList({
              params: {
                p: 1,
                pp: 500,
                engine_key: this.filterByEngine,
                keep: "_key,name,engine,role",
              },
            })
            .then((response) => {
              let locationsList = response?.data?.data?.result
              let locationKey = null
              if (locationsList.length > 0) {
                let displayLocation = locationsList.find((l) => {
                  let roleName = l?.role?.name
                  return roleName.toLowerCase() === "display"
                })
                locationKey = displayLocation
                  ? displayLocation._key
                  : locationsList[0]._key
              }
              resolve(locationKey)
            })
            .catch(() => {
              resolve(null)
            })
          return
        }
        resolve(null)
      })
    },
    initLocationFilters(locationKey) {
      let self = this
      this.$store
        .dispatch("getEngineLocationItem", { slug: locationKey })
        .then((res) => {
          if (res) {
            self.filterByLocation = res
            self.setFilterDate()
            self.queryParamsInited = true
            self.getEventsSheduledList()
            self.getEventsAnyList()
          }
        })
    },
    getPanelMaxHeight() {
      let maxHeight = parseInt(document.documentElement.clientHeight) - 375
      let ss = parseInt(maxHeight / 80)
      let mH = ss * 80
      mH = mH >= 80 ? mH : 80

      var elems = document.querySelectorAll(".v-data-table__wrapper")
      var index = 0,
        length = elems.length
      for (; index < length; index++) {
        elems[index].style.maxHeight = `${mH}px`
      }
    },
    setFilterDate(type = "today") {
      switch (type) {
        case "today":
          this.filterByDate = moment().format("YYYY-MM-DD")
          break
        case "next":
          if (this.filterByDate) {
            this.filterByDate = moment(this.filterByDate, "YYYY-MM-DD")
              .add(1, "d")
              .format("YYYY-MM-DD")
          } else {
            this.filterByDate = moment().format("YYYY-MM-DD")
          }
          break
        case "prev":
          if (this.filterByDate) {
            this.filterByDate = moment(this.filterByDate, "YYYY-MM-DD")
              .subtract(1, "d")
              .format("YYYY-MM-DD")
          } else {
            this.filterByDate = moment().format("YYYY-MM-DD")
          }
          break
        default:
          this.filterByDate = moment().format("YYYY-MM-DD")
      }
    },
    handleClickRow(event) {
      let simpleRouteParams = {
        path: `/store/event/playlists/${event._key}`,
        query: this.$route.query,
      }
      let hashRouteParams = {
        path: `/store/event/playlists/${event._key}/${this.link_hash}`,
        query: this.$route.query,
      }
      let routerParams = !this.link_hash ? simpleRouteParams : hashRouteParams
      this.$router.push(routerParams).catch((err) => {})
    },
    doSomething(e) {
      e.stopPropagation()
    },
    getEventsSheduledList: function () {
      let self = this
      if (self.$refs.refTableEventsSheduled && self.queryParamsInited) {
        let getParams = self.$refs.refTableEventsSheduled.getTableServerParams()
        getParams.is_scheduled = 1
        if (self.link_hash) getParams.engine_key = self.filterByEngine
        if (self.filterByDate) getParams.date = self.filterByDate
        if (self.filterByLocation)
          getParams.location_key = self.filterByLocation?._key
        if (self.filterByTimezone) getParams.time_zone = self.filterByTimezone
        if (!self.isSuperUser) getParams.group_key = self.currentUser.group_key
        self.$store
          .dispatch("getEventsList", { params: getParams })
          .then((resp) => {
            self.sheduledEvents = resp.events
            self.sheduledEventsCount = resp.count
          })
      }
    },
    getEventsAnyList: function () {
      let self = this
      if (self.$refs.refTableEventsAny && self.queryParamsInited) {
        let getParams = self.$refs.refTableEventsAny.getTableServerParams()
        getParams.is_scheduled = 0
        if (self.link_hash) getParams.engine_key = self.filterByEngine
        if (self.filterByDate) getParams.date = self.filterByDate
        if (self.filterByLocation)
          getParams.location_key = self.filterByLocation?._key
        if (!self.isSuperUser) getParams.group_key = self.currentUser.group_key
        self.$store
          .dispatch("getEventsList", { params: getParams })
          .then((resp) => {
            self.anyEvents = resp.events
            self.anyEventsCount = resp.count
          })
      }
    },
    deleteEvent(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this bundle event?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self
              .deleteEventWs(item)
              .then((response) => {
                if (response.data.status === "success") {
                  self.getEventsSheduledList()
                  self.getEventsAnyList()
                }
              })
              .catch((error) => {
                console.log(error)
              })
          }
        })
    },
    deleteEventWs(item) {
      let self = this
      return new Promise((resolve, reject) => {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.events.delete",
            args: { event_key: item._key },
          })
        )

        // If is no WS callback more than 6 second - reject
        let timeoutObj = setTimeout(() => {
          reject()
        }, 6000)

        self.$on("event-deleted", (response) => {
          clearTimeout(timeoutObj)
          if (get(response, "status", "") === "success") {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      })
    },
    getMembersRow(members) {
      return members.length > 0
        ? members.map((m) => m.engine?.name).join("<br>")
        : ""
    },
    switchStartedStatusInList(item) {
      let self = this
      if (self.activeEvent.is_started) {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.events.stop",
            args: { event_key: item._key },
          })
        )
      } else {
        self.websocket_common_conn.send(
          JSON.stringify({
            path: "1.0.events.start",
            args: { event_key: item._key },
          })
        )
      }
    },
  },

  computed: {
    ...mapGetters({
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      engineLocationsList: "engineLocationsList",
    }),

    showFilter() {
      return ["localhost", "dev.arht.redox-dev.com"].includes(
        window.location.hostname
      )
    },

    filterByTimezone: {
      get() {
        return this.$store.getters.eventFilters.timezone
      },
      set(val) {
        this.$store.dispatch("setEventTimezoneFilter", val)
      },
    },

    /**
     * When we access event by hash link we must in locations filter dropdown we must show only locations related to current engine
     * @returns {{engine_key}}
     */
    customFilterLocations() {
      return this.link_hash ? { engine_key: this.filterByEngine } : {}
    },
    filterByEngine() {
      return this.$store.state.moduleStore.eventFilters.engine_key
    },
    emptyIconUrl() {
      return `${window.location.origin}/images/41x41-808080.png`
    },
  },
}
</script>

<style lang="scss">
.page-wrapper {
  height: 100% !important;
}

@import "@/assets/scss/moon/components/events/event-common.scss";
</style>

<style lang="scss" scoped>
@import "@/assets/scss/moon/components/events/events-list.scss";
</style>
