<template>
  <div class="event-status-wrap d-flex align-center">
    <template v-if="event">
      <v-chip class="mr-2 chip-status" color="#1887C9" v-if="event.is_started">
        <v-icon left class="mr-3" size="14"> $vuetify.icons.active </v-icon>
        Active
      </v-chip>
      <v-chip class="mr-2 chip-status" color="#434343" v-if="!event.is_started">
        <v-icon left class="mr-3" size="14"> $vuetify.icons.ready </v-icon>
        Ready
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  name: "event-status-chip",

  props: {
    event: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.event-status-wrap {
  .chip-status {
    height: 24px;
    width: 98px;
  }
}

.chip-status {
  font: 400 11px Prompt-Regular !important;
  line-height: 17px;
  width: 100px;
  color: #fbfcfe;
}
</style>
