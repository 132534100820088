<template>
  <div>
    <!--    <v-row>-->
    <!--      <v-col-->
    <!--        cols="12"-->
    <!--        sm="12"-->
    <!--        md="12"-->
    <!--      >-->
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="dateProp"
      persistent
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="textDate"
          :label="label"
          :prepend-icon="prependIcon"
          readonly
          v-bind="attrs"
          v-on="on"
          @click:clear="dateProp = null"
          :outlined="outlined"
          :hide-details="hideDetails"
          small
          :dense="dense"
          :clearable="clearable"
          :class="classObject"
          :style="{ width: width }"
        ></v-text-field>
      </template>
      <v-date-picker v-model="dateTemp" scrollable>
        <v-btn @click="setTodayDate()" small> Today </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click="modal = false"> Cancel </v-btn>
        <v-btn
          text
          color="primary"
          @click="$refs.dialog.save(dateTemp)"
          class="pr-0 mr-0"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-dialog>
    <!--      </v-col>-->
    <!--    </v-row>-->
  </div>
</template>

<script>
import moment from "moment"
import { mapGetters } from "vuex"

export default {
  name: "DateFilter",

  props: {
    date: {
      type: [String, null],
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "Filter by date",
    },
    prependIcon: {
      type: [String, null],
      default: "mdi-calendar",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "auto",
    },
    inputClasses: {
      type: [Array, String],
      default: () => [],
    },
  },

  data: () => ({
    modal: false,
    dateTemp: null,
  }),

  methods: {
    setTodayDate() {
      this.dateTemp = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10)
    },
  },

  computed: {
    ...mapGetters({
      Pallette: "get_Pallette",
    }),
    dateProp: {
      get() {
        return this.date
      },
      set(val) {
        this.$emit("update:date", val)
      },
    },
    textDate: {
      get() {
        return this.dateProp ? moment(this.dateProp).format("MMM D, YYYY") : ""
      },
      set() {},
    },
    classObject() {
      return Array.isArray(this.inputClass)
        ? this.inputClass.map(() => {
            true
          })
        : { [this.inputClass]: true }
    },
  },
}
</script>
