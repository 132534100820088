<template>
  <div class="text-xs-left avatar-group-list">
    <div class="avatars-block">
      <v-tooltip bottom v-for="item in limitedItems" :key="item._key">
        <!-- eslint-disable-next-line -->
        <template v-slot:activator="{ on, attrs }">
          <v-badge avatar bordered overlap offset-x="15" offset-y="34">
            <template v-slot:badge>
              <v-avatar>
                <v-img :src="item.flag" alt="image"></v-img>
              </v-avatar>
            </template>

            <v-avatar class="avatar">
              <img :src="item.icon" alt="Avatar" v-on="on" />
            </v-avatar>
          </v-badge>
        </template>
        <div v-if="item.hasOwnProperty('location') && item.location">
          <div>{{ item.location.name }}</div>
          <div>{{ item.name }}</div>
        </div>
        <span v-if="!item.hasOwnProperty('location') || !item.location">{{
          item.name
        }}</span>
      </v-tooltip>
    </div>

    <v-menu v-if="extraItems" top max-height="235" min-width="300">
      <!-- eslint-disable-next-line -->
      <template v-slot:activator="{ on, attrs }">
        <div v-if="extraItems > 0" class="avatar-group-list__extra" v-on="on">
          <div class="plus">+{{ extraItems }}</div>
        </div>
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-avatar>
            <v-img :src="item.icon"></v-img>
          </v-list-item-avatar>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
          <v-list-item-avatar size="25" v-if="item.flag">
            <v-img :src="item.flag"></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "AvatarGroups",

  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    limit: {
      type: Number,
      default: 3,
    },
  },

  data: () => ({}),

  computed: {
    extraUsers() {
      return this.users.slice(3)
    },
    extraUsersLabels() {
      return this.users
        .slice(3)
        .map((user) => user.name)
        .join(", ")
    },
    extraItems() {
      let itemsCount = this.items.length
      return itemsCount - this.limit
    },
    limitedItems() {
      return this.items.slice(0, this.limit)
    },
  },
}
</script>

<style scoped lang="scss">
.avatar-group-list {
  display: flex;
  width: 150px;

  &__extra {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #d0d0d0;

    .plus {
      font: 400 13px Prompt-Regular;
      margin-left: 22px;
      width: 40px;
    }
  }
}

.avatars-block {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-right: 0px;
  margin-left: 10px;
}

.avatar {
  position: relative;
  border: 2px solid #2f3132;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  margin-left: -7px;

  &:hover .v-badge__badge {
    z-index: 3;
  }

  img {
    border: 2px solid #2f3132;
    width: 47px;
  }
}
</style>
